import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showErrorToast, showSuccessToast } from '../../utils/showToast';

axios.defaults.baseURL = 'https://test-backend.stravaporuch.com.ua/api/v1';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
const setAuthHeader = token => {

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const clearAuthHeader = () => {
  axios.defaults.headers.common.Authorization = '';
};

export const register = createAsyncThunk(
  '/register',
  async (formData, thunkAPI) => {
    try {
      const response = await axios.post('/security/register', formData);
      setAuthHeader(response.data.token);
      return response.data;
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;

        showErrorToast(message);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const valid = createAsyncThunk(
  '/validOneStep',
  async (formData, thunkAPI) => {
    try {
      const response = await axios.post('/security/validate-first-step', formData);
      return response.data;
    } catch (error) {

      // Перевірка наявності response
      if (error.response && error.response.data) {
        const { status, data } = error.response;
        console.log('status :>> ', data.details.email);
        // Перевірка наявності даних в error.response.data
        if (status === 422) {
          showErrorToast(data.details.email[0]);
        }

        console.log('error :>> ', error);
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  }
);




export const logIn = createAsyncThunk(
  '/login',
  async (credentials, thunkAPI) => {
    try {
      const response = await axios.post('/security/admin-login', credentials);

      setAuthHeader(response.data.token);
      return response.data;
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;

        showErrorToast(message);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const logOut = createAsyncThunk('/logout', async (_, thunkAPI) => {
  try {

    clearAuthHeader();
  } catch (error) {
    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        showErrorToast('Not authorized.');
      }
      if (status === 500) {
        showErrorToast('Server error.');
      }
    }
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getCurrentUser = createAsyncThunk(
  '/current',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const persistedToken = state.auth.token;

    if (persistedToken === null) {
      return thunkAPI.rejectWithValue('Unable to fetch user');
    }
    try {
      setAuthHeader(persistedToken);

      const response = await axios.get('/account/info');
      return response.data;

    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 401) {
          showErrorToast('Not authorized.');
        }
        if (status === 500) {
          showErrorToast('Server error.');
        }
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);



export const getMerchantBill = createAsyncThunk(
  '/download-merchant-bill',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const persistedToken = state.auth.token;

    if (persistedToken === null) {
      return thunkAPI.rejectWithValue('Unable to fetch user');
    }
    try {
      setAuthHeader(persistedToken);

      const response = await axios.get('/account/download-merchant-bill');
      return response.blob();

    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 401) {
          showErrorToast('Not authorized.');
        }
        if (status === 500) {
          showErrorToast('Server error.');
        }
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);



export const updateUser = createAsyncThunk(
  '/user',
  async (credentials, thunkAPI) => {
    try {
      const response = await axios.post('/settings/merchant', credentials);

      return response.data.merchant;
    } catch (error) {
      showErrorToast(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


export const updateUserLogo = createAsyncThunk(
  '/userLogo',
  async (credentials, thunkAPI) => {
    try {

      const response = await axios.post('/settings/upload-logo', credentials);
      return response.data.logo;
    } catch (error) {
      showErrorToast(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);




export const updateUserBagPicture = createAsyncThunk(
  '/userBagPicture',
  async (credentials, thunkAPI) => {
    try {
      const response = await axios.post('/settings/upload-bag-picture', credentials);
      return response.data.bag_picture;
    } catch (error) {
      showErrorToast(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);



export const deleteUserLogo = createAsyncThunk(
  '/deleteLogo',
  async (credentials, thunkAPI) => {
    try {
      await axios.post('/settings/delete-logo', credentials);

    } catch (error) {
      showErrorToast(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);




export const deleteUserBagPicture = createAsyncThunk(
  '/deleteBagPicture',
  async (credentials, thunkAPI) => {
    try {
      await axios.post('/settings/delete-bag-picture', credentials);

    } catch (error) {
      showErrorToast(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);






export const sendVerifyEmailUser = createAsyncThunk(
  '/resendVerifyEmail',
  async (credentials, thunkAPI) => {
    try {
      console.log('credentials :>> ', credentials);
      const response = await axios.post('/security/confirm-email', credentials);
      showSuccessToast(
        response.message
      );

      return credentials;
    } catch (error) {
      showErrorToast(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getVerifyEmailUser = createAsyncThunk(
  '/getVerifyEmail',
  async (verifyToken, thunkAPI) => {
    try {
      const response = await axios.get(`/verify/${verifyToken}`);

      return response.data.verify;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const recoveryRequest = createAsyncThunk(
  '/recovery-request',
  async (email, thunkAPI) => {
    try {
      const response = await axios.post('/security/recovery-request', email);
      console.log('response :>> ', response);
      // showSuccessToast(
      //   'The new password has been successfully sent to your email'
      // );
      return;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const changePassword = createAsyncThunk(
  '/changePassword',
  async (data, thunkAPI) => {
    try {
      await axios.post('/changePassword', data);
      showSuccessToast('Your password has been successfully updated');
      return;
    } catch (error) {
      showErrorToast('You have entered an invalid old password');
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


export const finalOnBoard = createAsyncThunk(
  '/finalOnBoard',
  async (_, thunkAPI) => {
    await axios.post('/settings/disable-onboarding');
    getCurrentUser();
    return false;


  }
);


export const renewPassword = createAsyncThunk(
  '/renewPassword',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/security/recovery', data);

      showSuccessToast('Your password has been successfully updated');
      return response;
    } catch (error) {
      showErrorToast('You have entered an invalid old password');
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


