import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ReactComponent as RepeatIcon } from '../../../images/arrow/repeat.svg';
import { ReactComponent as ShopBagIcon } from '../../../images/shoppingBag.svg';
import { ReactComponent as CoinsIcon } from '../../../images/coins.svg';
import { ReactComponent as QuestIcon } from '../../../images/circlequest.svg'
export const AllBox = styled.div`
  display: flex;
  flex-direction:column;
  align-items: flex-start;
gap:24px;
/* overflow-y: auto; */
width:100%;
min-width:1024px ;
height:80vh;

`;



export const BagsBox = styled.div`
 
border-radius: 16px;
padding: 16px;
padding-bottom: 0;
padding-right: 0;
width: 100%;

background: var(--colors-grey-50);
  /* @media screen and (min-width: 375px) {
    width: 375px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    width: 768px;
  }

  @media screen and (min-width: 1440px) {
    width: 1440px;
    display: flex;
    max-height: 100vh;
  } */
`;


export const BagsTitleBox = styled.div`
  display: flex;
align-items: flex-start;
justify-content: space-between;
border-bottom: 1px solid var(--colors-grey-200);
padding: 0px 0px 12px 0px;
width:100%;


`;

export const BagsBodyBox = styled.div`
width: 100%;
height: fit-content;
max-height: 85vh;
padding: 16px 16px 16px 0;
/* overflow-y: auto; */
`;


export const BagsTitle = styled.h2`
display: flex;
align-items: center;
gap:8px;
font-family: var(--font-family);
font-weight: 600;
font-size: 20px;
line-height: 135%;
color: var(--colors-grey-800);
`;


export const NavList = styled.ul`
display: flex;
flex-wrap: wrap;
justify-content: center;
gap:8px;
width: 100%;
`;

export const NavItem = styled.li`
width: calc((100% - 8px) / 2);
&:last-child{
  margin-top: 16px;

}
`;


export const NavElement = styled.div`
display: block;
background: var(--colors-grey-25);
border: 1px solid var(--colors-grey-200);
border-radius: 16px;
padding: 16px;
min-height: 100%;
height: fit-content;
width: 100%;
`;

export const IconShopBag = styled(ShopBagIcon)`
  width: 32px;
  height: 32px;
margin-bottom: 12px;
`;
export const IconCoins = styled(CoinsIcon)`
  width: 32px;
  height: 32px;
margin-bottom: 12px;
`;
export const IconRepeat = styled(RepeatIcon)`
  width: 32px;
  height: 32px;
margin-bottom: 12px;
`;
export const IconQuest = styled(QuestIcon)`
  width: 32px;
  height: 32px;
margin-bottom: 12px;
`;

export const Icon = styled.img`
  width: 32px;
  height: 32px;
margin-bottom: 12px;
`;
export const NavTitle = styled.h3`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
margin-bottom: 4px;
`;

export const NavDesc = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-500);
`;

export const NavDesc1 = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
text-align: center;
color: var(--colors-grey-600);
margin-bottom: 8px;
`;

export const NavMod = styled.p`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-primary-600);
`;