
import { LinkNav, ListNav, ListNavItem } from './ServiceNavToolbar.styled';
import { useLocation, } from 'react-router-dom';
import dayjs from 'dayjs';

import 'dayjs/locale/uk'; // Завантажуємо українську локалізацію

dayjs.locale('uk'); // Встановлюємо українську локалізацію






const ServiceNavToolbar= () => {



  const location = useLocation();

  const arrays = location.pathname.split('/');
  console.log('arrays :>> ', arrays);
  const currentPage = arrays[arrays.length - 1].includes("-") ? arrays[arrays.length - 2] : arrays[arrays.length - 1];
console.log('currentPage :>> ', currentPage);

  return (

    <ListNav>
      <ListNavItem>
        <LinkNav

          to="/services"
          className={`${currentPage !== 'services' ? '' : 'active'}`}
        >Типові питання</LinkNav>
      </ListNavItem>
      <ListNavItem>
        <LinkNav

          to={`/services/question`}
          className={`${currentPage === 'question' ? 'active' : ''}`}
        >Питання від мерчантів</LinkNav>
      </ListNavItem>
      <ListNavItem>
        <LinkNav

          to={`/services/question_client`}
          className={`${currentPage === 'question_client' ? 'active' : ''}`}
        >Питання від клієнтів</LinkNav>
      </ListNavItem>
    </ListNav >


  );
};

export default ServiceNavToolbar;
