import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const systemApi = createApi({
  reducerPath: 'system',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://test-backend.stravaporuch.com.ua/api/v1/settings/system',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: false,
  keepUnusedDataFor: 1,

  endpoints: (builder) => ({

    // GET: List billing plans
    getSystems: builder.query({
      query: () => ({
      url: `/`,
        method: 'GET',
      }),
      providesTags: ['System'],
    }),

 

    // POST: Updating an existing billing plan
    updateSystem: builder.mutation({
      query: (settings) => ({
        url: `/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: settings,
      }),
      invalidatesTags: ['System'],
    }),

  }),
});

export const {
  useGetSystemsQuery,
  useUpdateSystemMutation

} = systemApi;
