
import { useEffect, useState } from "react";
import { WrapQuest, QuestList, QuestItem, IconArrowDown, QuestTitle, QuestDesc, IconEdit, IconTrash, PaginationContainer, PrevIcon, PageNumbersContainer, PaginationButton, NextIcon } from "./QuestionList.styled";
import AddFaqItem from "components/modal/AddFaqItem/AddFaqItem";
import { useNavigate } from "react-router-dom";


const QuestionList = ({ questions, isModalOpen, setModalOpen, handleCloseModal, types, title, setTypes, setTitle, categoryId, currentPage }) => {
  const [expandedIds, setExpandedIds] = useState([]);
  const navigate = useNavigate();

const [selectedThemeItem, setSelectedThemeItem]=useState({});
  



  // Обробник кліку, що розгортає або згортає відповідь
  const toggleQuestion = (id) => {
    setExpandedIds(prevIds =>
      prevIds.includes(id)
        ? prevIds.filter(expandedId => expandedId !== id) // Якщо id вже є в масиві, видаляємо його (згортання)
        : [...prevIds, id] // Інакше додаємо id в масив (розгортання)
    );
  };

  const handleEventClick = (data) => {
   
    setSelectedThemeItem(data)
    setModalOpen(true);

  };
  const handleEditClick = (event, { id, title, description }) => {
    event.stopPropagation(); // Зупиняє поширення події
    setTypes('edit');
    setTitle('Редагувати питання');
    handleEventClick({ id, title, description });
  };
  
  const handleDeleteClick = (event, { id, title,  description }) => {
    event.stopPropagation(); // Зупиняє поширення події
    setTypes('del');
    setTitle('Видалити питання');
    handleEventClick({ id, title,  description });
  };
  const reverseQuestion = [...questions].reverse();
  const itemsPerPage = 10; // Кількість елементів на сторінці
  const totalPages = Math.ceil((questions?.length || 0) / itemsPerPage); // Розрахунок загальної кількості сторінок
  const displayedQuestions = questions?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      navigate(`?category=${categoryId}&page=${page}`);
    }
  };




  return (

    <QuestList>
      {
        displayedQuestions?.map(({ id, title, description, }) => 
        (<QuestItem key={id}>
          <WrapQuest onClick={() => toggleQuestion(id)}>
            <QuestTitle>{title}</QuestTitle>
            <IconArrowDown up={expandedIds.includes(id) ? "true" : "false"} />

          </WrapQuest>
          <QuestDesc expanded={expandedIds.includes(id)}>
            {description}
          </QuestDesc>
          <IconEdit onClick={(event) => handleEditClick(event, { id, title,  description })} />
          <IconTrash onClick={(event) => handleDeleteClick(event, { id, title,  description })} />
        </QuestItem>

        ))
      }
      {totalPages > 1 && (
        <PaginationContainer>
          {/* Кнопка "Назад" */}
          <PrevIcon
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />

          {/* Логіка відображення сторінок */}
          <PageNumbersContainer>
            {(() => {
              const pages = [];
              const leftBoundary = 2; // Дві перші сторінки
              const rightBoundary = totalPages - 1; // Дві останні сторінки
              const currentRange = 1; // Діапазон сторінок навколо поточної

              // Завжди додаємо першу сторінку
              pages.push(
                <PaginationButton
                  key={`page-1`}
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                >
                  1
                </PaginationButton>
              );

              // Додати "..." між першою та середнім блоком
              if (currentPage > leftBoundary + currentRange) {
                pages.push(<span key="dots-start">...</span>);
              }

              // Додати сторінки навколо поточної
              for (
                let i = Math.max(currentPage - currentRange, leftBoundary);
                i <= Math.min(currentPage + currentRange, rightBoundary);
                i++
              ) {
                pages.push(
                  <PaginationButton
                    key={`page-${i}`}
                    onClick={() => handlePageChange(i)}
                    disabled={i === currentPage}
                  >
                    {i}
                  </PaginationButton>
                );
              }

              // Додати "..." між середнім блоком та останньою сторінкою
              if (currentPage < rightBoundary - currentRange) {
                pages.push(<span key="dots-end">...</span>);
              }

              // Завжди додаємо останню сторінку
              if (totalPages > 1) {
                pages.push(
                  <PaginationButton
                    key={`page-${totalPages}`}
                    onClick={() => handlePageChange(totalPages)}
                    disabled={currentPage === totalPages}
                  >
                    {totalPages}
                  </PaginationButton>
                );
              }

              return pages;
            })()}
          </PageNumbersContainer>

          {/* Кнопка "Вперед" */}
          <NextIcon
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </PaginationContainer>
      )}

 {isModalOpen && (
          <AddFaqItem categoryId ={categoryId }onClose={handleCloseModal} title={title} types={types} themeItem={selectedThemeItem} />
        )}
    </QuestList>


  );
};

export default QuestionList;
