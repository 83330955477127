import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const ordersApi = createApi({
  reducerPath: 'orders',

  baseQuery: fetchBaseQuery({
    baseUrl: 'https://test-backend.stravaporuch.com.ua/api/v1/orders',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: ['Orders'],

  endpoints: builder => ({
    getOrders: builder.query({
      query: ({ page = 1, keyword = '', user_id = '', merchant_id = '' } = {}) => {

        const params = {
          page, // додаємо параметр page до запиту
          keyword,
          user_id,
          merchant_id
        };
        return {
          url: `/all`,
          method: 'GET',
          params,
        };
      },
      transformResponse: (response, meta) => {
        const pagination = {
          currentPage: meta.response.headers.get('x-pagination-current-page'),
          pageCount: meta.response.headers.get('x-pagination-page-count'),
          perPage: meta.response.headers.get('x-pagination-per-page'),
          totalCount: meta.response.headers.get('x-pagination-total-count'),
        };
        return { data: response, pagination };
      },
      providesTags: ['Orders'],
    }),
    returnOrders: builder.mutation({
      query: ({ id, comment }) => ({
        url: `/return?id=${id}&comment=${comment}`,
        method: 'POST',
      }),
      invalidatesTags: ['Orders'],
    }),
    viewOrder: builder.mutation({
      query: ({ id }) => ({
        url: `/view?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Orders'],
    }),

    confirmOrder: builder.mutation({
      query: ({ id }) => ({
        url: `/confirm?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Orders'],
    }),

    cancelOrder: builder.mutation({
      query: ({ id }) => ({
        url: `/cancel?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Orders'],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useReturnOrdersMutation,
  useViewOrderMutation,
  useConfirmOrderMutation,
  useCancelOrderMutation,
} = ordersApi;
