
import {
  Container,
  List,
  ListItem,
  LinkNav,

  IconStatistics,
  ListBox,
  TitleName,
  ImgTitle,
  IconSettings,
  IconServices,
  IconHome,
  IconFinance,
  PreImg,
  TitleBox,
  IconUser,
  IconComments,
  IconDocs,
  IconSearch,
} from './UserNav.styled';

import { useLocation } from 'react-router-dom';
import LogoutBtn from '../LogoutBtn';

import { selectUser } from '../../../redux/auth/selectors';
import { useSelector } from 'react-redux';
import { getInitial } from 'utils/function';
import { useEffect, useState } from 'react';



const UserNav = ({ closeSidebar, isHovered, onboard }) => {

  const [merchant, setMerchant] = useState();
  const user = useSelector(selectUser);
  useEffect(() => { if (user) { setMerchant(user.merchant) } }, [user])

  const location = useLocation();

  /* eslint-disable */
  const [_, currentPage] = location.pathname.split('/');
  /* eslint-enable */
console.log('currentPage1 :>> ', currentPage);
  return (
    <Container>
      <TitleBox> <PreImg>A</PreImg> {isHovered || onboard ? <TitleName>Адмін панель</TitleName> : null}</TitleBox>
      <ListBox>

        <List>

          <ListItem className={`${currentPage === 'main' ? 'active' : ''} ${isHovered || onboard ? 'hovered' : ''} `}>
            <LinkNav
              onClick={closeSidebar}
              to="/calendar"
              className={`${currentPage === 'main' ? 'active' : ''} text `}
            >
              <IconUser
                isactive={currentPage === 'main' ? 'active' : ''}
                className={`icon ${isHovered || onboard ? 'hovered' : ''}`}
              />
              {isHovered || onboard ? "Користувачі" : ""}
            </LinkNav>
          </ListItem>
          {/* <ListItem className={`${currentPage === 'search' ? 'active' : ''} ${isHovered || onboard ? 'hovered' : ''}`}>
            <LinkNav
              onClick={closeSidebar}
              to="/search"
              className={`${currentPage === 'search' ? 'active' : ''} text`}
            >
              <IconSearch
                isactive={currentPage === 'search' ? 'active' : ''}
                className={`icon ${isHovered || onboard ? 'hovered' : ''}`}
              />

              {isHovered || onboard ? "Пошук" : ""}
            </LinkNav>
          </ListItem> */}
          <ListItem className={`${currentPage === 'comments' ? 'active' : ''} ${isHovered || onboard ? 'hovered' : ''}`}>
            <LinkNav
              onClick={closeSidebar}
              to="/comments"
              className={`${currentPage === 'comments' ? 'active' : ''} text`}
            >
              <IconComments
                isactive={currentPage === 'comments' ? 'active' : ''}
                className={`icon ${isHovered || onboard ? 'hovered' : ''}`}
              />

              {isHovered || onboard ? "Коментарі" : ""}
            </LinkNav>
          </ListItem>

          <ListItem className={`${currentPage === 'documents' ? 'active' : ''} ${isHovered || onboard ? 'hovered' : ''}`}>
            <LinkNav
              onClick={closeSidebar}
              to="/documents"
              className={`${currentPage === 'documents' ? 'active' : ''} text`}
            >
              <IconDocs
                isactive={currentPage === 'documents' ? 'active' : ''}
                className={`icon ${isHovered || onboard ? 'hovered' : ''}`}
              />

              {isHovered || onboard ? "Документи" : ""}
            </LinkNav>
          </ListItem>
          
          {/* <ListItem className={`${currentPage === 'statistics' ? 'active' : ''} ${isHovered || onboard ? 'hovered' : ''}`}>
            <LinkNav
              onClick={closeSidebar}
              to="/statistics"
              className={`${currentPage === 'statistics' ? 'active' : ''} text`}
            >
              <IconStatistics
                isactive={currentPage === 'statistics' ? 'active' : ''}
                className={`icon ${isHovered || onboard ? 'hovered' : ''}`}
              />

              {isHovered || onboard ? "Статистика" : ""}
            </LinkNav>
          </ListItem> */}
          {/* <ListItem className={`${currentPage === 'financial' ? 'active' : ''} ${isHovered || onboard ? 'hovered' : ''}`}>
            <LinkNav
              onClick={closeSidebar}
              to="/financial"
              className={`${currentPage === 'financial' ? 'active' : ''} text`}

            >
              <IconFinance
                isactive={currentPage === 'financial' ? 'active' : ''}
                className={`icon ${isHovered || onboard ? 'hovered' : ''}`}
              />

              {isHovered || onboard ? "Фінанси" : ""}
            </LinkNav>
          </ListItem> */}
        </List>
        <List>
          <ListItem className={`${currentPage === 'services' ? 'active' : ''} ${isHovered || onboard ? 'hovered' : ''}`}>
            <LinkNav
              onClick={closeSidebar}
              to="/services"
              className={`${currentPage === 'services' ? 'active' : ''} text`}
            >
              <IconServices
                isactive={currentPage === 'services' ? 'active' : ''}
                className={`icon ${isHovered || onboard ? 'hovered' : ''}`}
              />

              {isHovered || onboard ? "Служба підтримки" : ""}
            </LinkNav>
          </ListItem>
          <ListItem className={`${currentPage === 'settings' ? 'active' : ''} ${isHovered || onboard ? 'hovered' : ''}`}>
            <LinkNav
              onClick={closeSidebar}
              to="/settings"
              className={`${currentPage === 'settings' ? 'active' : ''}  text`}
            >
              <IconSettings
                isactive={currentPage === 'settings' ? 'active' : ''}
                className={`icon ${isHovered || onboard ? 'hovered' : ''}`}
              />

              {isHovered || onboard ? "Налаштування" : ""}
            </LinkNav>
          </ListItem>
          <ListItem className={` ${isHovered || onboard ? 'hovered' : ''}`} none={true} >
            <LogoutBtn isHovered={isHovered} onboard={onboard} />
          </ListItem>
        </List>

      </ListBox>
    </Container>
  );
};

export default UserNav;
