import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import {
  Container,

  PageHeader,
  NotificBtn,
  ButtonBack,
  BackIcon,
  NotificWrap,
} from './Header.styled';
import { useGetBagsQuery, useGetMonthlyTasksQuery } from '../../../redux/bags/bagsApi';
import { useGetNotificationsQuery } from '../../../redux/notification/notificationApi';
import NotificationModal from 'components/modal/NotificationModal/NotificationModal';
import { useWebSocket } from 'utils/Websoket';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';
import { selectToken } from '../../../redux/auth/selectors';


const Header = ({ openSidebar, isLightTheme, setIsLightTheme }) => {
  const { data: NotiData, isLoading: isLoadingNoti } = useGetNotificationsQuery();
  const modalRef = useRef(null);
  const token = useSelector(selectToken);
  const navigate = useNavigate();
  const location = useLocation();
  const [activePage, setActivePage] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [visibleBack, setVisibleBack] = useState(false);
  const [notificationsAr, setNotificationsAr] = useState([]);
  const [markUnRead, setMarkUnRead] = useState(false);
  const [searchParams] = useSearchParams();
  const currentPath = location.pathname;
  const lastPage = currentPath.split('/').length - 1 >= 3 ? currentPath.split('/')[currentPath.split('/').length - 2] : currentPath.split('/')[currentPath.split('/').length - 1];
  console.log('lastPage :>> ', lastPage);
  /* eslint-disable */
  const [_, currentPage, __, currentDate] = currentPath.split('/');
  /* eslint-enable */
  const [socket, setSocket] = useState(null);



  const handleBackClick = () => {
    // Повернення до попередньої сторінки
    navigate(-1);
  };

  useEffect(() => {
    const baseUrl = "https://test-backend.stravaporuch.com.ua:443";
    const newSocket = io.connect(baseUrl, {
      path: "/ws",
      query: {
        auth_token: token,
      },
      secure: true
    });

    newSocket.on('connect', () => {
      console.log('Socket підключено');
    });

    newSocket.on('message', (data) => {

      console.log('повідомлення :>> ', data);
      const parsedCreatedAt = new Date(data.createdAt); // Передаємо оригінальний рядок без змін
      const newNotification = {
        id: data.id,
        title: data.title,
        message: data.message,
        createdAt: isNaN(parsedCreatedAt) ? new Date() : parsedCreatedAt, // Перевіряємо, чи дата валідна
        read: data.unread
      };

      // Додаємо нове повідомлення в початок масиву сповіщень
      setNotificationsAr(prevNotifications => [newNotification, ...prevNotifications]);


    });









    newSocket.on('error', (err) => {
      console.error('Помилка Socket:', err);
    });

    newSocket.on('disconnect', () => {
      console.log('WebSocket відключено');
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);





  useEffect(() => {

    switch (currentPage) {


      case 'main':
        return setActivePage("Головна");

      case 'statistics':
        return setActivePage("Статистика");

      case 'financial':
        return setActivePage("Фінанси");
      case 'comments':
        return setActivePage("Коментарі");
      case 'search':
        return setActivePage("Пошук");
      case 'services':
        return setActivePage("Служба підтримки");

      case 'settings':
        return setActivePage("Налаштування");
      case 'documents':
        return setActivePage("Документи");
      default:
        setActivePage('');
        return;
    }
  }, [currentPage]);

  const handleOpenModal = () => {
    setModalOpen(!isModalOpen);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setModalOpen(false);

    document.body.style.overflow = 'auto';

  };

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }
    };
    const handleClickOutside = (event) => {
      if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        handleCloseModal();
      }
    }
    if (isModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isModalOpen]);




  useEffect(() => {
    const categoryId = searchParams.get('category');
    if (categoryId || lastPage === "archivebags") {
      setVisibleBack(true)
    } else setVisibleBack(false)
    if (lastPage === "edit" || lastPage === "adddoc" || lastPage === "viewbag" || lastPage === "vieworder" || lastPage === "addshop" || lastPage === "viewuser") setVisibleBack(true);
  }, [searchParams, lastPage]);

  // Використання хука useWebSocket
  // useWebSocket((message) => {
  //   // Обробка отриманого повідомлення
  //   const newNotification = {
  //     id: message.id, // Переконайся, що у твоєму повідомленні є id
  //     title: message.title,
  //     message: message.body,
  //     createdAt: new Date(), // Або отримуй дату з повідомлення, якщо вона є
  //     read: false,
  //   };
  //   setNotificationsAr(prevNotifications => [...prevNotifications, newNotification]);
  // }, (error) => {
  //   console.error('WebSocket error:', error);
  // });

  useEffect(() => {

    if (NotiData) {

      setNotificationsAr([...NotiData.data,])

    }

  }, [NotiData]);



  useEffect(() => {



    setMarkUnRead(notificationsAr.filter(item => !item.read).length > 0 ? true : false)

  }, [notificationsAr]);



  return (
    <Container>
      {visibleBack ? <ButtonBack onClick={handleBackClick}><BackIcon />Назад</ButtonBack> :
        <PageHeader >{activePage}</PageHeader>}

      <NotificWrap onClick={handleOpenModal} hide={markUnRead ? 'true' : 'false'} >
        <NotificBtn />
        {isModalOpen && !isLoadingNoti && (
          <NotificationModal onClose={handleCloseModal} notificationsAr={notificationsAr} setNotificationsAr={setNotificationsAr} markUnRead={markUnRead} />
        )}
      </NotificWrap>




    </Container>
  );
};

export default Header;
